<template>
    <router-link v-if="attributes.to" v-bind="attributes" v-on="listeners">
        <slot />
    </router-link>

    <a v-else v-bind="attributes" v-on="listeners">
        <slot />
    </a>
</template>

<script>
export default {
    props: {

        color: {
            type: String,
            required: false,
            default: () => "primary",
        },
    },
    data() {
        return {
            baseClasses: "block text-sm font-medium hover:underline cursor-pointer",
        };
    },
    computed: {
        classes() {
            return `link ${this.baseClasses} ${this.colors[this.color]}`;
        },
        /**
         * The colors available for the link component.
         */
        colors() {
            return {
                /**
                 * @color Primary
                 */
                primary: "text-primary-500",

                /**
                 * @color Secondary
                 */
                secondary: "text-secondary-500",

                /**
                 * @color Secondary Inverse
                 */
                secondaryInverse: "hover:text-secondary-500",
            };
        },
        attributes() {
            return {
                class: this.classes,
                ...this.$attrs,
            };
        },
        listeners() {
            return {
                ...this.$listeners,
            };
        },
    },
};
</script>
